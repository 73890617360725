@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.nav-bar {
  width: 100%;
  background: #ff0000;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 60px;
}

.nav {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 940px;
  align-items: center
}

.nav > .logo {
  width: 300px;
  margin-right: auto;
  padding-top: 8px;
}

.nav > .logo-min {
  width: 32px;
  margin-right: auto;
  padding: 6px 0  0 16px;
}

.nav > .logo > img {
  width: 180px;
}

.nav > .logo-min > img {
  width: 32px;
}

.nav > .nav-item {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  margin: 0 10px;
  padding: 8px 16px;
  border-radius: 3px;
  background-color: crimson;
}

.nav > .nav-item:hover {
  background-color: rgb(177, 18, 49)
}

.container {
  width: 940px;
  margin: 0 auto
}

.uppertext {
  text-transform: uppercase;
}

.lowertext {
  text-transform: lowercase;
}

h3 {
  font-weight: 500;
}

.cliente {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.consulta-form > label {
  font-size: 12px;
  padding-right: 8px;
  font-weight: 500;
}

.consulta-form > input {
  font-size: 12px;
  padding: 8px;
  width: 200px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.consulta-form > button {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  margin: 0 10px;
  padding: 8px 16px;
  border-radius: 3px;
  background-color: crimson;
  border: 0;
  cursor: pointer;
}

.consulta-form > button:disabled {
  background-color: #ccc;
  color: #4a4a4a;
}

.mail-form span {
  font-size: 12px;
  color: steelblue;
  padding-top: 8px;
  margin-left: 110px;
  display: inline-block;
}

.facturas {
 width: 100%;
 border-spacing: 0;
}

.facturas > thead {
  background-color: #4a4a4a;
  color: #fff;
  text-align: left;
}

.facturas > thead > tr > th {
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
}

.facturas > tbody > tr {
  background-color: #fff;
}

.facturas > tbody > tr:nth-child(even) {
  background-color: #f3f3f3;
}

.facturas > tbody > tr > td {
  font-size: 14px;
  padding: 4px;
}

.facturas > tbody > tr > td > a {
  text-decoration: none;
  color: #4a4a4a;
  font-size: 16px;
  margin: 0 10px;
}

.facturas > tbody > tr > td > a:hover {
  color: crimson;
}

.facturas > tbody > tr > td > span.sendmail {
  color: #4a4a4a;
  font-size: 16px;
  margin: 0 10px;
  cursor: pointer;
}

.facturas > tbody > tr > td > span.sendmail:hover {
  color: crimson;
}

.mail-send-msg {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.mail-send-msg p {
  margin-top: 0;
  font-size: 14px;
  color: steelblue;
  padding-right: 20px;
}

.factura-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.factura-form {
  display: flex;
  flex-direction: column;
  width: 410px;
}

.form-input-group {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;
}

.factura-form > .form-input-group > label {
  font-size: 12px;
  padding-right: 8px;
  font-weight: 500;
}

.factura-form > .form-input-group > input {
  font-size: 12px;
  padding: 8px;
  width: 260px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.factura-form > .form-input-group > select {
  width: 278px;
  border: 1px solid #e4eaec;
  background-color: #fff;
  border-radius: 4px;
  padding: 8px;
  outline: 0;
  font-size: .8125rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
}

.factura-form > button {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  margin-top: 16px;
  margin-left: auto;
  padding: 8px 16px;
  border-radius: 3px;
  background-color: crimson;
  border: 0;
  cursor: pointer;
  width: 100px;
}

.factura-form > button:disabled {
  background-color: #ccc;
  color: #4a4a4a;
}

.razon-social {
  font-size: 12px;
  margin-bottom: 10px;
  margin-left: auto;
  color: #FF5733;
}

.factura-consult {
  display: flex;
  flex: 1 0;
  padding: 0 16px;
  flex-direction: column;
}

.alert {
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  font-size: 14px;
  display: flex;
}

.center {
  justify-content: center;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.factura {
  font-size: 14px;
  padding: 8px;
  margin-bottom: 8px;
}

.factura a {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  margin: 0 10px;
  padding: 8px 16px;
  border-radius: 3px;
  background-color: crimson;
  border: 0;
  cursor: pointer;
}

.logo-min {
  display: none;
}

@media (max-width: 768px) {
  .nav, .container {
    width: 680px;
  }

  .consulta-form {
    display: flex;
    height: 30px;
    margin-right: 8px;
  }

  .consulta-form > input {
    width: 160px;
  }
}

@media (max-width: 420px) {
  .nav, .container, .factura-form {
    width: 100%;
  }

  .factura-container {
    flex-direction: column;
  }

  .logo {
    display: none;
  }

  .logo-min {
    display: block;
  }

  .nav > .nav-item {
    padding: 8px;
    margin: 0 6px;
    font-size: 12px;
  }

  .container-box {
    padding: 0 16px;
  }

  .factura-form {
    margin-bottom: 16px;
  }

  .factura-form > .form-input-group > input {
    width: 210px;
  }

  .factura-form > .form-input-group > select {
    width: 228px;
  }

  .factura-consult {
    padding: 0;
  }

  .cliente {
    flex-direction: column;
  }

  .consulta-form {
    display: flex;
    margin-bottom: 8px;
    justify-content: flex-end;
  }

  .consulta-form > button {
    margin: 0 0 0 8px;
  }

  .mail-form span {
    margin: 0;
  }

  .hide-xs {
    display: none;
  }
}

